<template>
  <div>
    <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
      <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="forgotPassword" v-if="step === 'forgot_password'">
        <h1 class="lg:text-2xl text-xl font-semibold mb-6">
          Quên mật khẩu
        </h1>
        <div :class="{'bg-green-500': status === 'success', 'bg-red-500': status === 'error'}" class="border p-4 relative rounded-md uk-alert" v-if="message">
          <p class="text-white">{{ message }}</p>
        </div>
        <div>
          <label class="mb-0">Nhập tên đăng nhập / email của bạn</label>
          <input v-model="identity" type="text" placeholder="Username hoặc email" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          Bạn đã có tài khoản? <router-link :to="{name: 'Login'}" class="text-blue-800 ms-1">Đăng nhập</router-link>
        </div>
        <div>
          <button :disabled="disabled" type="submit" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
            Reset mật khẩu</button>
        </div>
      </form>
      <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="validateCode" autocomplete="off" v-if="step === 'validate_code'">
        <h1 class="lg:text-2xl text-xl font-semibold mb-6">
          Xác thực mã
        </h1>
        <div :class="{'bg-green-500': status === 'success', 'bg-red-500': status === 'error'}" class="border p-4 relative rounded-md uk-alert" v-if="message">
          <p class="text-white">{{ message }}</p>
        </div>
        <div>
          <label class="mb-0" for="code">Nhập mã xác thực gửi trong email của bạn:</label>
          <input id="code" v-model="code" type="text" placeholder="Mã xác thực" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          <button :disabled="disabled" type="submit" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
            Xác thực mã
          </button>
        </div>
        <div>
          <button type="button" v-on:click="step = 'forgot_password'; message = ''" class="font-semibold p-2 mt-5 rounded-md text-center text-gray-500 w-full">
            Quay lại
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {saveToken} from "@/core/services/jwt.service";
import {changePageTitle} from "../core/services/utils.service";

export default {
  name: 'ForgotPassword',
  data() {
    return {
      identity: "",
      code: "",
      message: "",
      disabled: false,
      type: "",
      step: "forgot_password",
      status: "error"
    }
  },
  methods: {
    forgotPassword() {
      this.disabled = true;

      let query = `mutation($identity: String!) {
        forgotPassword(identity: $identity)
      }`;

      ApiService.graphql(query, {identity: this.identity})
          .then(({data}) => {
            if (data.data && data.data.forgotPassword) {
              this.step = "validate_code";
              this.code = "";
              this.disabled = false;
              this.message = "";
            } else {
              this.disabled = false;
              this.message = data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.message = response.message;
            this.status = "error";
          });
    },
    validateCode() {
      this.disabled = true;

      let query = `mutation($identity: String!, $code: String!) {
        validateCode(identity: $identity, code: $code, type: "forgot_password")
      }`;

      ApiService.graphql(query, {identity: this.identity, code: this.code})
          .then(({data}) => {
            if (data.data && data.data.validateCode) {
              saveToken(data.data.validateCode);
              this.$router.push({name: "ChangePassword"});
            } else {
              this.disabled = false;
              this.message = data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.message = response.message;
            this.status = "error";
          });
    }
  },
  mounted() {
    changePageTitle("Quên mật khẩu")
  }
}
</script>
